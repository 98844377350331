import {$glVars} from './common';

const i18n = $glVars.i18n;

/**
 * GLOBAL
 */
i18n.addTag("appName", "Books Samael Aun Weor", "Livres Samaël Aun Weor", "Libros Samael Aun Weor");
i18n.addTag("operationResultTitle", "Books Samael Aun Weor", "Livres Samaël Aun Weor", "Libros Samael Aun Weor");
i18n.addTag("saw", "Samael Aun Weor", "Samaël Aun Weor", "Samael Aun Weor");
i18n.addTag("search", "Search", "Rechercher", "Búsqueda");
i18n.addTag("msgConfirmDeletion", "Do you confirm the deletion?", "Confirmez-vous la suppression?", "¿Confirma la supresión?");
i18n.addTag("msgConfirm", "Are you sure you want to proceed?", "Êtes-vous sur de vouloir continuer?", "¿Estás seguro de que quieres continuar?");
i18n.addTag("msgSuccess", "The action has been completed successfully.", "L'action a été complétée avec succès.", "La acción se ha completado con éxito.");
i18n.addTag("en", "English", "Anglais", "Inglés");
i18n.addTag("fr", "French", "Français", "Francés");
i18n.addTag("es", "Spanish", "Espagnol", "Español");
i18n.addTag("yes", "Yes", "Oui", "Sí");
i18n.addTag("no", "No", "Non", "No");
i18n.addTag("samaelaunweor", "Samael Aun Weor", "Samaël Aun Weor", "Samael Aun Weor");
i18n.addTag("gnosticeditions", "Gnostic Editions", "Éditions Gnostiques", "Ediciones Gnósticas");
i18n.addTag("by", "By", "Par", "De");
i18n.addTag("request", "Request", "Demander", "Solicitar");
i18n.addTag("apply", "Apply", "Appliquer", "Aplicar");

i18n.addTag("home", "Home", "Accueil", "Inicio");
i18n.addTag("contactUs", "Contact Us", "Contactez-nous", "Contáctenos");
i18n.addTag("availableBooks", "Available Books", "Livres disponibles", "Libros disponibles");
i18n.addTag("chooseYourLanguage", "Choose your language", "Choisissez votre langue", "Elija su idioma");
i18n.addTag("email", "Email", "Courriel", "Correo electrónico");
i18n.addTag("phone", "Phone", "Téléphone", "teléfono");
i18n.addTag("copyright", "© Copyright", "© Droit d'auteur", "© Derechos de autor");
i18n.addTag("allRightsReserved", "All rights reserved.", "Tous droits réservés.", "Todos los derechos reservados.");

i18n.addTag("privacyPolice", "Privacy Police", "Politique de confidentialité", "Política de privacidad");
i18n.addTag("lastUpdate", "Last Update", "Dernière mise à jour", "Última actualización");
i18n.addTag("cookieMessage", "We and our partners use cookies to personalize your experience. By using our website and services, you agree to our use of cookies in accordance with our", "Nous, ainsi que nos partenaires, utilisons les cookies pour personnaliser votre expérience. En utilisant notre site web et nos services, vous acceptez notre utilisation des cookies conformément à notre", "Nosotros y nuestros socios utilizamos cookies para personalizar su experiencia. Al utilizar nuestro sitio web y nuestros servicios, aceptas el uso que hacemos de las cookies de acuerdo con nuestra");
i18n.addTag("iUnderstand", "I understand", "Je comprends", "Entiendo");

i18n.addTag("privacyPoliceContent", `<h2 className='mt-5'>Introduction</h2>
                <p>This Privacy Policy covers <b>books.gnosis.ca</b>. We provide online book catalog and related services through our website.</p>
                <p>It is designed to explain how we gather, utilize, disclose, and safeguard your personal data when you access and use our website.</p>
                <p>By registering for, or using our services, you accept this Privacy Policy.</p>
                <p>We may periodically update this Privacy Policy to reflect changes in our practices or for legal reasons.</p>
                
                <h2 className='mt-5'>Data We Collect About You</h2>
                <p>We may collect personal information that you willingly provide when utilizing our services. This information might be collected during account registration or when you contact us.</p>
                <p>These are the collected data:</p>
                <ul>
                    <li>Name: To personalize your experience.</li>
                    <li>Email Address: To communicate with you, send updates and marketing purposes.</li>
                    <li>City and country: This data helps us enhance our website and can be used for marketing purposes.</li>
                    <li>Any other data: You may provide voluntarily, such as preferences or user-generated content.</li>
                </ul>

                <p>To make a purchase, you may need to provide a valid payment method (e.g., credit card or PayPal account) and billing address. Your payment information will be collected and processed by our authorized payment vendors. We do not directly collect or store credit or debit card numbers ourselves in the ordinary course of processing transactions.</p>

                <h2 className='mt-5'>How We Use Your Data</h2>
                <p>We may use your data for the following purposes:</p>

                <ul>
                    <li>Identification and authentication: We use your data to verify you when you access your account, and to uphold the security of our services.</li>
                    <li>Providing and Enhancing Services: We use your data to provide our services, process and fulfill orders, continually enhance your experience on our website and provide customer support.</li>
                    <li>Communicating with you: We use your data when we communicate with you (e.g., when we respond to a customer support or other inquiry).</li>
                </ul>

                <h2 className='mt-5 '>With Whom We Share Your Data</h2>
                <p>We do not sell, trade, or transfer your personal information to third parties without your consent.However, we may share your data with trusted service providers who assist us in operating our website, subject to strict confidentiality agreements.</p>

                <h2 className='mt-5 '>Cookies and Tracking Technologies</h2>
                <p>Our website may utilize cookies and similar tracking technologies to collect information about your browsing behavior. You can manage your cookie preferences through your browser settings.</p>
                <p>Here is the list of cookies used on our website:</p>
                <ul>
                    <li>Session identification</li>
                    <li>Selected language</li>
                    <li>Your last cursor position in the book reader</li>
                    <li>Acceptance of the cookie policy</li>
                </ul>

                <h2 className='mt-5 '>Legal and Safety-Related Disclosures</h2>
                <p>We may disclose your data in response to official requests (e.g., court orders, subpoenas, search warrants, national security requests, etc.) that we receive from government authorities or parties to legal proceedings. </p>
                <p>We may disclose user data where we reasonably believe that someone's life is at risk. For example, if we become aware of a person threatening to commit suicide, we may share that person's data with appropriate entities that may have the ability to help.</p>
                <p>We may disclose user data to report suspected crimes.</p>
                <p>We may disclose user data in situations involving legal claims against us or one of our users.</p>

                <h2 className='mt-5 '>Data Retention and Protecting Your Information</h2>
                <p>We retain your data for as long as you have an account. When you close an account, we will delete your data.</p>
                <p>We may retain logs of automatically collected information (for internal analytics and security purposes); your email address; communications with you; and your transactional information (for auditing, tax, and financial purposes). When we no longer have a business reason for retaining your data, we will delete or anonymize it.</p>
                <p>We use technical security measures to safeguard your data from unauthorized or accidental disclosure. Despite these efforts, no information system can be 100% secure, so we cannot guarantee the absolute security of your information. Users also have a role to play in keeping their data safe. We encourage you to use a unique and hard-to-guess password for your account and to not share it with others. You should only grant access rights to people who you know and trust, and, even then, you should be careful in granting all access rights. You should monitor your account regularly. If you believe that someone has gained access to your account without your permission, please contact us immediately so that we can investigate.</p>

                <h2 className='mt-5 '>Your Rights</h2>
                <p>Access: You have the right to access the personal information we hold about you. Feel free to contact us for a copy of your data.</p>
                <p>Correction: If you believe the personal information we hold about you is inaccurate or incomplete, you can request corrections.</p>
                <p>Deletion: You can request the deletion of your personal information.</p>
                <p>Objection: You have the right to object to the processing of your personal information for certain purposes, such as direct marketing.</p>

                <h2 className='mt-5 '>Third-Party Services</h2>
                <p>We may provide access to online services that we do not own or operate via hyperlinks or integrations. These services fall outside of this Privacy Policy and we have no control over the data they collect. Once on a third-party site, your data may be collected by others.</p>

                <h2 className='mt-5 '>How to Contact Us</h2>
                <p>If you have questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at <b>books.saw@gmail.com.</b></p>`, 
                
                
                
                `<h2 className='mt-5'>Introduction</h2>
                <p>Cette politique de confidentialité couvre <b>books.gnosis.ca</b>. Nous fournissons un catalogue de livres en ligne et des services connexes par l'intermédiaire de notre site web.</p>
                <p>Il a pour but d'expliquer comment nous recueillons, utilisons, divulguons et protégeons vos données à caractère personnel lorsque vous accédez à notre site web et l'utilisez.</p>
                <p>En vous inscrivant ou en utilisant nos services, vous acceptez la présente politique de confidentialité.</p>
                <p>Nous pouvons périodiquement mettre à jour la présente politique de protection de la vie privée pour refléter des changements dans nos pratiques ou pour des raisons juridiques.</p>
                
                <h2 className='mt-5'>Les données que nous recueillons sur vous</h2>
                <p>Nous pouvons recueillir des informations personnelles que vous fournissez volontairement lorsque vous utilisez nos services. Ces informations peuvent être collectées lors de l'enregistrement du compte ou lorsque vous nous contactez.</p>
                <p>Ce sont les données collectées :</p>
                <ul>
                    <li>Nom : Pour personnaliser votre expérience.</li>
                    <li>Courriel : Pour communiquer avec vous, envoyer des mises à jour et à des fins de marketing.</li>
                    <li>Ville et pays : Ces données nous aident à améliorer notre site web et peuvent être utilisées à des fins de marketing.</li>
                    <li>Toute autre donnée : que vous pouvez fournir volontairement, telles que les préférences ou le contenu généré par l'utilisateur.</li>
                </ul>

                <p>Pour effectuer un achat, vous devrez peut-être fournir une méthode de paiement valide (par exemple, une carte de crédit ou un compte PayPal) et une adresse de facturation. Vos informations de paiement seront collectées et traitées par nos fournisseurs de paiement autorisés. Nous ne collectons ni ne stockons directement les numéros de cartes de crédit ou de débit dans le cadre normal du traitement des transactions.</p>

                <h2 className='mt-5'>Comment nous utilisons vos données</h2>
                <p>Nous pouvons utiliser vos données aux fins suivantes :</p>

                <ul>
                    <li>Identification et authentification : Nous utilisons vos données pour vous vérifier lorsque vous accédez à votre compte et pour assurer la sécurité de nos services.</li>
                    <li>Fournir et améliorer les services : Nous utilisons vos données pour fournir nos services, traiter et exécuter les commandes, améliorer continuellement votre expérience sur notre site web et fournir une assistance à la clientèle.</li>
                    <li>Communiquer avec vous : Nous utilisons vos données lorsque nous communiquons avec vous (par exemple, lorsque nous répondons à une demande d'assistance à la clientèle ou à toute autre demande).</li>
                </ul>

                <h2 className='mt-5 '>Avec qui nous partageons vos données</h2>
                <p>Nous ne vendons pas, n'échangeons pas et ne transférons pas vos informations personnelles à des tiers sans votre consentement. Cependant, nous pouvons partager vos données avec des fournisseurs de services de confiance qui nous aident à exploiter notre site web, sous réserve d'accords de confidentialité stricts.</p>

                <h2 className='mt-5 '>Cookies et technologies de suivi</h2>
                <p>Notre site web peut utiliser des cookies et des technologies de suivi similaires pour collecter des informations sur votre comportement de navigation. Vous pouvez gérer vos préférences en matière de cookies par le biais des paramètres de votre navigateur.</p>
                <p>Voici la liste des cookies utilisés sur notre site web :</p>
                <ul>
                    <li>Identification de la session</li>
                    <li>Langue choisie</li>
                    <li>Dernière position du curseur dans le lecteur de livres</li>
                    <li>Acceptation de la politique en matière de cookies</li>
                </ul>

                <h2 className='mt-5 '>Divulgations juridiques et liées à la sécurité</h2>
                <p>Nous pouvons divulguer vos données en réponse à des demandes officielles (par exemple, ordonnances judiciaires, citations à comparaître, mandats de perquisition, demandes de sécurité nationale, etc.) que nous recevons de la part d'autorités gouvernementales ou de parties à des procédures judiciaires. </p>
                <p>Nous pouvons divulguer les données des utilisateurs lorsque nous pensons raisonnablement que la vie d'une personne est en danger. Par exemple, si nous apprenons qu'une personne menace de se suicider, nous pouvons partager les données de cette personne avec des entités appropriées susceptibles de l'aider.</p>
                <p>Nous pouvons divulguer les données des utilisateurs pour signaler des délits présumés.</p>
                <p>Nous pouvons divulguer les données des utilisateurs dans des situations impliquant des réclamations légales à notre encontre ou à l'encontre de l'un de nos utilisateurs.</p>

                <h2 className='mt-5 '>Conservation des données et protection des informations</h2>
                <p>Nous conservons vos données aussi longtemps que vous possédez un compte. Lorsque vous fermez un compte, nous supprimons vos données.</p>
                <p>Nous pouvons conserver des journaux d'informations collectées automatiquement (à des fins d'analyse interne et de sécurité), votre adresse électronique, vos communications avec vous et vos informations transactionnelles (à des fins d'audit, fiscales et financières). Lorsque nous n'avons plus de raison professionnelle de conserver vos données, nous les supprimons ou les rendons anonymes.</p>
                <p>Nous utilisons des mesures de sécurité techniques pour protéger vos données contre toute divulgation non autorisée ou accidentelle. Malgré ces efforts, aucun système d'information ne peut être sûr à 100 % et nous ne pouvons donc pas garantir la sécurité absolue de vos informations. Les utilisateurs ont également un rôle à jouer dans la protection de leurs données. Nous vous encourageons à utiliser un mot de passe unique et difficile à deviner pour votre compte et à ne pas le partager avec d'autres personnes. Vous ne devez accorder des droits d'accès qu'aux personnes que vous connaissez et en qui vous avez confiance et, même dans ce cas, vous devez faire preuve de prudence dans l'octroi de tous les droits d'accès. Vous devez surveiller votre compte régulièrement. Si vous pensez que quelqu'un a accédé à votre compte sans votre autorisation, veuillez nous contacter immédiatement afin que nous puissions enquêter.</p>

                <h2 className='mt-5 '>Vos droits</h2>
                <p>Le droit d'accès : Vous avez le droit d'accéder aux informations personnelles que nous détenons à votre sujet. N'hésitez pas à nous contacter pour obtenir une copie de vos données.</p>
                <p>Correction : Si vous pensez que les informations personnelles que nous détenons à votre sujet sont inexactes ou incomplètes, vous pouvez demander à ce qu'elles soient corrigées.</p>
                <p>Suppression : Vous pouvez demander la suppression de vos données personnelles.</p>
                <p>Opposition : Vous avez le droit de vous opposer au traitement de vos données personnelles à certaines fins, telles que le marketing direct.</p>

                <h2 className='mt-5 '>Services de tiers</h2>
                <p>Nous pouvons fournir un accès à des services en ligne que nous ne possédons pas ou que nous n'exploitons pas par le biais d'hyperliens ou d'intégrations. Ces services ne relèvent pas de la présente politique de confidentialité et nous n'avons aucun contrôle sur les données qu'ils collectent. Une fois sur un site tiers, vos données peuvent être collectées par d'autres.</p>

                <h2 className='mt-5 '>Comment nous contacter</h2>
                <p>Si vous avez des questions, des préoccupations ou des demandes concernant la présente politique de confidentialité ou nos pratiques en matière de données, veuillez nous contacter à l'adresse suivante : <b>books.saw@gmail.com</b>.</p>`, 
                
                
                
                `<h2 className='mt-5'>Introduction</h2>
                <p>This Privacy Policy covers <b>books.gnosis.ca</b>. We provide online book catalog and related services through our website.</p>
                <p>It is designed to explain how we gather, utilize, disclose, and safeguard your personal data when you access and use our website.</p>
                <p>By registering for, or using our services, you accept this Privacy Policy.</p>
                <p>We may periodically update this Privacy Policy to reflect changes in our practices or for legal reasons.</p>
                
                <h2 className='mt-5'>Data We Collect About You</h2>
                <p>We may collect personal information that you willingly provide when utilizing our services. This information might be collected during account registration or when you contact us.</p>
                <p>These are the collected data:</p>
                <ul>
                    <li>Name: To personalize your experience.</li>
                    <li>Email Address: To communicate with you, send updates and marketing purposes.</li>
                    <li>City and country: This data helps us enhance our website and can be used for marketing purposes.</li>
                    <li>Any other data: You may provide voluntarily, such as preferences or user-generated content.</li>
                </ul>

                <p>To make a purchase, you may need to provide a valid payment method (e.g., credit card or PayPal account) and billing address. Your payment information will be collected and processed by our authorized payment vendors. We do not directly collect or store credit or debit card numbers ourselves in the ordinary course of processing transactions.</p>

                <h2 className='mt-5'>How We Use Your Data</h2>
                <p>We may use your data for the following purposes:</p>

                <ul>
                    <li>Identification and authentication: We use your data to verify you when you access your account, and to uphold the security of our services.</li>
                    <li>Providing and Enhancing Services: We use your data to provide our services, process and fulfill orders, continually enhance your experience on our website and provide customer support.</li>
                    <li>Communicating with you: We use your data when we communicate with you (e.g., when we respond to a customer support or other inquiry).</li>
                </ul>

                <h2 className='mt-5 '>With Whom We Share Your Data</h2>
                <p>We do not sell, trade, or transfer your personal information to third parties without your consent.However, we may share your data with trusted service providers who assist us in operating our website, subject to strict confidentiality agreements.</p>

                <h2 className='mt-5 '>Cookies and Tracking Technologies</h2>
                <p>Our website may utilize cookies and similar tracking technologies to collect information about your browsing behavior. You can manage your cookie preferences through your browser settings.</p>
                <p>Here is the list of cookies used on our website:</p>
                <ul>
                    <li>Session identification</li>
                    <li>Selected language</li>
                    <li>Your last cursor position in the book reader</li>
                    <li>Acceptance of the cookie policy</li>
                </ul>

                <h2 className='mt-5 '>Legal and Safety-Related Disclosures</h2>
                <p>We may disclose your data in response to official requests (e.g., court orders, subpoenas, search warrants, national security requests, etc.) that we receive from government authorities or parties to legal proceedings. </p>
                <p>We may disclose user data where we reasonably believe that someone's life is at risk. For example, if we become aware of a person threatening to commit suicide, we may share that person's data with appropriate entities that may have the ability to help.</p>
                <p>We may disclose user data to report suspected crimes.</p>
                <p>We may disclose user data in situations involving legal claims against us or one of our users.</p>

                <h2 className='mt-5 '>Data Retention and Protecting Your Information</h2>
                <p>We retain your data for as long as you have an account. When you close an account, we will delete your data.</p>
                <p>We may retain logs of automatically collected information (for internal analytics and security purposes); your email address; communications with you; and your transactional information (for auditing, tax, and financial purposes). When we no longer have a business reason for retaining your data, we will delete or anonymize it.</p>
                <p>We use technical security measures to safeguard your data from unauthorized or accidental disclosure. Despite these efforts, no information system can be 100% secure, so we cannot guarantee the absolute security of your information. Users also have a role to play in keeping their data safe. We encourage you to use a unique and hard-to-guess password for your account and to not share it with others. You should only grant access rights to people who you know and trust, and, even then, you should be careful in granting all access rights. You should monitor your account regularly. If you believe that someone has gained access to your account without your permission, please contact us immediately so that we can investigate.</p>

                <h2 className='mt-5 '>Your Rights</h2>
                <p>Access: You have the right to access the personal information we hold about you. Feel free to contact us for a copy of your data.</p>
                <p>Correction: If you believe the personal information we hold about you is inaccurate or incomplete, you can request corrections.</p>
                <p>Deletion: You can request the deletion of your personal information.</p>
                <p>Objection: You have the right to object to the processing of your personal information for certain purposes, such as direct marketing.</p>

                <h2 className='mt-5 '>Third-Party Services</h2>
                <p>We may provide access to online services that we do not own or operate via hyperlinks or integrations. These services fall outside of this Privacy Policy and we have no control over the data they collect. Once on a third-party site, your data may be collected by others.</p>

                <h2 className='mt-5 '>How to Contact Us</h2>
                <p>If you have questions, concerns, or requests regarding this Privacy Policy or our data practices, please contact us at <b>books.saw@gmail.com.</b></p>`);


i18n.addTag("sawBioTitle", "The Great Gnostic Master of Modern Times", "Le Grand Maître Gnostique des temps modernes", "El Gran Maestro Gnóstico de los Tiempos Modernos");
i18n.addTag("sawBioP1", "Samael Aun Weor is the restorer of the universal Gnosis in our time and the founder of the International Gnostic Movement.", "Samaël Aun Weor est le précurseur de la Gnose universelle à notre époque et le fondateur du Mouvement Gnostique International.", "Samael Aun Weor es el instaurador de la Gnosis universal en nuestra época y el fundador del Movimiento Gnóstico Internacional.");
i18n.addTag("sawBioP2", "He is called the Master of synthesis and emphasis for his ability to expound the essentials of the great mysteries.", "On l'appelle le Maître de la synthèse et de l'emphase pour sa capacité à exposer l'essentiel des grands mystères.", "Es llamado el Maestro de la sintesis y del enfasis por su capacidad de exponer lo esencial de los grandes misterios.");
i18n.addTag("sawBioP3", "In a direct and revealing language, he wrote more than 60 books and gave countless lectures in which the teachings of the ancient initiatory mysteries are gathered.", "Dans un langage direct et révélateur, il a écrit plus de 60 ouvrages et donné d'innombrables conférences dans lesquelles sont rassemblés les enseignements des anciens mystères initiatiques.", "En un lenguaje directo y revelador, él escribió más de 60 obras y dictó innumerables conferencias en las que se reunen las ensenanzas de los antiguos misterios iniciaticos.");
i18n.addTag("sawBioP4", "For 27 years he worked intensively to spread Gnostic knowledge.", "Pendant 27 ans, il a travaillé intensément à la diffusion de la connaissance Gnostique.", "Durante 27 años estuvo trabajando intensamente para difundir el conocimiento Gnóstico.");
i18n.addTag("sawBioP5", "The purpose of his work is the intimate self-realization of the Being.", "Le but de son œuvre est l'autoréalisation de l'Être.", "La finalidad de su obra es la autorealizacion intima del Ser.");

i18n.addTag("shippingInfo", "Shipping to Canada, United States and elsewhere in the world!", "Expédition au Canada, aux États-Unis et ailleurs dans le monde !", "Enviamos a Canadá, Estados Unidos y el resto del mundo.");
i18n.addTag("otherAuthors", "Other Authors", "Autres auteurs", "Otros autores");
i18n.addTag("newReleasesIn", "New Release in", "Nouvelle parution en", "Nuevo libro en");
i18n.addTag("noResult", "No results were found for this search.", "Aucun résultat n a été trouvé pour cette recherche.", "No se han encontrado resultados para esta búsqueda.");
i18n.addTag("printOnDemand", "Print on-demand", "Impression à la demande", "Impresión bajo demanda");
i18n.addTag("ebook", "E-Book", "Livre électronique", "Libro electrónico");
i18n.addTag("paperback", "Paperback", "Couverture souple", "Tapa blanda");
i18n.addTag("hardcover", "Hardcover", "Couverture rigide", "Tapa dura");
i18n.addTag("placeAnOrder", "To Place an Order", "Pour commander", "Hacer un pedido");

/**
 * SEARCH
 */
i18n.addTag("searchResult", "About $COUNT results ($ITEMS items per page)", "Environ $COUNT résultats ($ITEMS éléments par page)", "Cerca de $COUNT resultados ($ITEMS artículos por página)");
i18n.addTag("seeMore", "See more", "Voir plus", "Ver más");
i18n.addTag("seeLess", "See less", "Voir moins", "Ver menos");
i18n.addTag("goToTheBook", "Go to the book", "Aller au livre", "Ir al libro");

/**
 * BOOKS
 */
i18n.addTag("cancel", "Cancel", "Annuler", "Cancelar");
i18n.addTag("close", "Close", "Fermer", "Cerrar");
i18n.addTag("addToCart", "Add to Cart", "Ajouter au panier", "Añadir al carrito");
i18n.addTag("addToCartResult", "Product added to cart successfully.", "Produit ajouté au panier avec succès.", "Producto agregado al carrito exitosamente.");
i18n.addTag("myeBooks", "My eBooks", "Mes livres électroniques", "Mis libros electrónicos");
i18n.addTag("shoppingCart", "Shopping Cart", "Panier", "Carrito de compras");
i18n.addTag("cartIsEmpty", "The cart is empty.", "Le panier est vide.", "El carrito está vacío.");
i18n.addTag("quantity", "Quantity", "Quantité", "Cantidad");
i18n.addTag("removeItemFromCart", "Remove item from cart", "Supprimer l'article du panier", "Quitar artículo del carrito");
i18n.addTag("subtotal", "Subtotal", "Sous-total", "Subtotal");
i18n.addTag("items", "Items", "Articles", "Artículos");
i18n.addTag("proceedToCheckout", "Proceed to checkout", "Passer à la caisse", "Proceder al pago");
i18n.addTag("orderCheckout", "Order Checkout", "Paiement de la commande", "Pago del pedido");
i18n.addTag("paymentMethod", "Payment Method", "Mode de paiement", "Método de pago");
i18n.addTag("placeOrder", "Place your order", "Passer votre commande", "Realiza tu pedido");
i18n.addTag("returnMyCart", "Return to my cart", "Retourner à mon panier", "Volver a mi carrito");
i18n.addTag("myCart", "My cart", "Mon panier", "Mi carrito");
i18n.addTag("termsPurchase", "I understand that I am purchasing a user license that is valid until December 31 of the current year and is automatically renewed each year at no additional costs. This license provides full access to the product(s) as long as the Books Samael Aun Weor platform is available. If, for any reason, this platform is no longer available, my user license will also be canceled, and I will no longer have access to the service.",
     "Je comprends que j'achète une licence d'utilisation valable jusqu'au 31 décembre de l'année en cours et renouvelée automatiquement chaque année sans frais supplémentaires. Cette licence donne un accès complet au(x) produit(s) tant que la plateforme Books Samael Aun Weor est disponible. Si, pour une raison quelconque, cette plateforme n'est plus disponible, ma licence d'utilisation sera également annulée et je n'aurai plus accès au service.", 
     "Entiendo que estoy adquiriendo una licencia de uso que es válida hasta el 31 de diciembre del año en curso y se renueva automáticamente cada año sin costo adicional. Esta licencia me proporciona acceso total al(los) producto(s) (e-book) mientras la plataforma Libros Samael Aun Weor esté disponible. Si por alguna razón esta plataforma ya no está disponible, mi licencia de usuario también será cancelada y ya no tendré acceso al servicio.");
i18n.addTag("acceptTerms", "I have read and accept the terms of use", "J'ai lu et j'accepte les conditions d'utilisation", "He leído y acepto las condiciones de uso");
i18n.addTag("termsUse", "Terms of use", "Conditions d'utilisation", "Condiciones de uso");
i18n.addTag("clickSeeEbookTutorial", "See eBook tutorial", "Voir tutoriel livre électronique", "Ver tutorial libro electrónico");
i18n.addTag("ebookTutorial", "eBook tutorial", "Tutoriel livre électronique", "Tutorial libro electrónico");
i18n.addTag("showOnlyEbooks", "Show only eBooks", "Afficher uniquement les livres électroniques", "Mostrar solo libros electrónicos");

/**
 * BOOK READER
 */
i18n.addTag("back", "Back", "Revenir", "Volver");
i18n.addTag("fullScreen", "Full Screen", "Plein écran", "Pantalla completa");
i18n.addTag("fontSize", "Font Size", "Taille de police", "Tamaño de fuente");
i18n.addTag("backgroundColor", "Background Color", "Couleur de l'arrière plan", "Color de fondo");
i18n.addTag("noBookFound", "The book was not found or you did not purchase it.", "Le livre n'a pas été trouvé ou vous ne l'avez pas acheté.", "El libro no fue encontrado o no lo compraste.");
i18n.addTag("readIt", "Read It", "Lire le livre", "Leer el libro");
/**
 * LOGIN
 */
i18n.addTag("fullName", "Full Name", "Nom complet", 'Nombre completo');
i18n.addTag("login", "Login", "Nom de l'utilisateur", 'Acceso');
i18n.addTag("password", "Password", "Mot de passe", 'Contraseña');
i18n.addTag("email", "Email", "Courriel", 'Correo electrónico');
i18n.addTag("city", "City", "Ville", 'Ciudad');
i18n.addTag("country", "Country", "Pays", 'País');
i18n.addTag("signIn", "Sign In", "Connexion", 'Conexión');
i18n.addTag("signOut", "Sign Out", "Déconnexion", 'Desconectar');
i18n.addTag("msgSignInFailed", "The sign in has failed. Please try again.", "La connexion a échoué. Veuillez réessayer.", "El inicio de sesión falló. Inténtelo de nuevo.");
i18n.addTag("alreadyHaveAccount", "Do you already have an account?", "Avez-vous déjà un compte?", '¿Ya tiene una cuenta?');
i18n.addTag("haveForgottenPassword", "Have you forgotten your password?", "Avez-vouz oublié votre mot de passe ?", '¿Ha olvidado su contraseña?');
i18n.addTag("details", "Details", "Détails", 'Detalles');
i18n.addTag("invalidToken", "Invalid token.", "Jeton invalide.", 'Token inválido.');
i18n.addTag("errorOccurred", "An error has occurred.", "Une erreur s'est produite.", 'Se ha producido un error.');
i18n.addTag("accountSuspended", "This account has been suspended because the number of login attempts exceeded the attempt limit. Contact the administrator.", "Ce compte a été suspendu car le nombre de tentatives de connexion a dépassé la limite fixée. Contactez l'administrateur.", 'Esta cuenta ha sido suspendida porque el número de intentos de inicio de sesión ha superado el límite de intentos. Póngase en contacto con el administrador.');
i18n.addTag("accountDisabled", "This account is disabled. Contact the administrator.", "Ce compte est désactivé. Contactez l'administrateur.", 'Esta cuenta está deshabilitada. Póngase en contacto con el administrador.');
i18n.addTag("confirmEmail", "Your account is created but you need to confirm your email. Please check your email account or, if necessary, your spam folder.", "Votre compte est créé mais vous devez confirmer votre courriel. Veuillez vérifier votre compte de messagerie ou, si nécessaire, votre dossier de courrier indésirable.", 'Su cuenta está creada pero necesita confirmar su correo electrónico. Compruebe su cuenta de correo electrónico o, si es necesario, su carpeta de correo no deseado.');
i18n.addTag("emailConfirmed", "Your email has been confirmed. Your account is ready to use.", "Votre courriel a été confirmé. Votre compte est prêt à être utilisé.", 'Tu correo electrónico ha sido confirmado. Su cuenta está lista para usar.');
i18n.addTag("supportCookies", "Your browser must support cookies", "Votre navigateur doit supporter les témoins (cookies)", 'Su navegador debe ser compatible con las cookies');
i18n.addTag("firstTimeHere", "Is this your first time here?", "Est-ce votre première fois ici?", '¿Es esta su primera vez aquí?');
i18n.addTag("createMyAccount", "Create my account", "Créer mon compte", 'Crear mi cuenta');
i18n.addTag("passwordResetRequest", "Password reset request", "Demande de réinitialisation de mot de passe", 'Solicitud de restablecimiento de contraseña');
i18n.addTag("setMyNewPassword", "Set my new password", "Définir mon nouveau mot de passe", 'Establecer mi nueva contraseña');
i18n.addTag("setPasswordResult", "A new password has been set. Proceed to login.", "Un nouveau mot de passe a été défini. Procédez à la connexion.", 'Se ha establecido una nueva contraseña. Proceda a iniciar sesión.');
i18n.addTag("emailAlreadyExist", "This email is already connected to an account. Try to log in or if you forgot your password, you can try to recover it from the login form.", "Cet email est déjà connecté à un compte. Essayez de vous connecter ou si vous avez oublié votre mot de passe, vous pouvez essayer de le récupérer depuis le formulaire de connexion.", 'Este correo electrónico ya está conectado a una cuenta. Intente iniciar sesión o, si olvidó su contraseña, puede intentar recuperarla desde el formulario de inicio de sesión.');
i18n.addTag("recoverPassword", 
        "We have received your request to recover your account. If an account was registered using the email provided, we will send you an email with an account recovery link. Please wait a few minutes for the email to be sent, and be sure to check your spam folder if the email was flagged incorrectly.", 
        "Nous avons reçu votre demande pour récupérer votre compte. Si un compte a été enregistré à l'aide du courrier électronique fourni, nous vous enverrons un courriel avec un lien de récupération de compte. Veuillez attendre quelques minutes pour que le courriel soit envoyé, et assurez-vous de vérifier votre dossier de courrier indésirable si le courriel a été signalé incorrectement.", 
        "Hemos recibido su solicitud para recuperar su cuenta. Si se registró una cuenta utilizando el correo electrónico proporcionado, le enviaremos un correo electrónico con un enlace de recuperación de cuenta. Espere unos minutos hasta que se envíe el correo electrónico y asegúrese de revisar su carpeta de correo no deseado si el correo electrónico se marcó incorrectamente.");
i18n.addTag("passwordGuideline", "At least 1 uppercase, 1 lowercase, 1 number, 1 Symbol (!@#$%^&*_=+-) and minimum 8 characters and maximum 12 characters.", "Au moins 1 majuscule, 1 minuscule, 1 chiffre, 1 symbole (!@#$%^&*_=+-) et minimum 8 caractères et maximum 12 caractères.", 'Al menos 1 mayúscula, 1 minúscula, 1 número, 1 símbolo (!@#$%^&*_=+-) y mínimo 8 caracteres y máximo 12 caracteres.');
i18n.addTag("showPassword", "Show password", "Afficher le mot de passe", 'Mostrar contraseña');
i18n.addTag("hidePassword", "Hide password", "Cacher le mot de passe", 'Ocultar contraseña');



i18n.loadTags();
