//import Moment from 'moment'
import {Cookies, I18n} from "../libs/utils/Utils";
import {FeedbackCtrl} from "../libs/components/Feedback";
import {AppWebApi} from "./AppWebApi";

export class Utils{
    static getUrlVars(){
        var vars, uri;

        vars = {};

        uri = decodeURI(window.location.href);

        uri.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });

        return vars;
    }

	static filterBooksByLang(dataProvider, lang){
		let result = dataProvider.filter((item) => {
            for(let item2 of item.productList){
                if(item2.language === lang){
                    return true;
                }
            }
            return false;
        })

		return result;
  	}

  	static getBookListByLang(bookList){
		let result = (bookList && bookList.length > 0 ? {} : null);

		for(let book of bookList){
			for(let product of book.productList){
			if(!result[product.language]){
				result[product.language] = [];
			}
			result[product.language].push(book);
			}
		}

		return result;
  	}
}

export class AppCookies
{
    getLang(defaultValue){return Cookies.get('lang', defaultValue);}
    setLang(value){ Cookies.set('lang', value, 9999);}

    getSessionId(defaultValue){return Cookies.get('sid', defaultValue);}
    setSessionId(value){ Cookies.set('sid', value, 30);}

    getLastPlayback(userId, productId, defaultValue){return Cookies.get(`playback-${userId}-${productId}`, defaultValue);}
    setLastPlayback(userId, productId, value){ Cookies.set(`playback-${userId}-${productId}`, value, 518400);} //1 year

    getDismissCookiePolicy(defaultValue){return Cookies.get('cookiepolicy', defaultValue);}
    setDismissCookiePolicy(value){ Cookies.set('cookiepolicy', value, 518400);} //1 year
}

export class Assets
{
 //  static about = require("../assets/about.png");
   // static customers = require('../assets/handshake.svg');
   static brand = require('../assets/eagle.png');
   static brand2 = require('../assets/books-saw.png');
   static banner1 = require('../assets/banner-saw.jpg');
   static sawBio = require('../assets/saw-bio.jpg');
   static photoSaw1 = require('../assets/saw1.png');
   static photoSaw2 = require('../assets/saw2.jpg');
   static photoSaw3 = require('../assets/saw3.jpg');
   static photoSaw4 = require('../assets/saw4.jpg');
   static photoSaw5 = require('../assets/saw5.jpg');
   static photoSaw7 = require('../assets/saw7.jpg');
   static photoSaw8 = require('../assets/saw8.png');
   static photoSaw9 = require('../assets/saw9.jpg');
   static photoSaw10 = require('../assets/saw10.jpg');
   static photoSaw11 = require('../assets/saw11.jpg');
   static photoSaw12 = require('../assets/saw12.jpg');
   static photoSaw13 = require('../assets/saw13.jpg');
   static photoSaw14 = require('../assets/saw14.jpg');
   static sawSignature = require('../assets/saw-signature.png');
}

export const $glVars = {
  signedUser: null,
  feedback: new FeedbackCtrl(),
  i18n: new I18n(),
  webApi: new AppWebApi(),
  cookies: new AppCookies(),
  routeCtrl: null,
  data: {
    availableProducts: []
  },
  urlParams: {},
}